import React from "react";

const productCardStyle =
  "[&_h4]:flex [&_h4]:gap-x-3 [&_h4]:font-bold [&_h4]:text-[20px] [&_h4]:leaing-[30px] [&_h4]:text-[#0B1320] [&_h4]:mb-5 [&_.product:not(:last-child)]:pb-[30px] [&_.product:not(:last-child)]:mb-[30px] [&_ul]:list-disc [&_ul]:pl-6 [&_li]:text-sm [&_li]:leading-6";

const CampaignOverview = () => {
  return (
    <div className="max-w-[863px] mx-auto px-4 xl:px-0 pb-20">
      <h2 className=" text-4xl leading-[50px] text-[#0B1320] text-center mb-[52px] font-bold">
        キャンペーン概要・対象製品
      </h2>
      <div className="flex flex-col md:flex-row gap-x-5 xl:gap-x-[52px] gap-y-[30px] mb-[50px]">
        <div className="flex-1 p-[30px] shadow-[0px_2px_6px_#00000040] w-full lg:w-[400px] rounded-[10px]">
          <h3 className="mb-6 text-2xl leading-9 text-[#0B1320] text-center font-bold">
            OMNICHANNEL SUITE
          </h3>
          <div
            className={`${productCardStyle} [&_.product:not(:last-child)]:border-b [&_.product:not(:last-child)]:border-[#E5E7EB]`}
          >
            <div className="product">
              <h4 className="product_title">
                <img
                  src="/images/tp/fdesk-4.svg"
                  width="37px"
                  height="38px"
                  alt="Freshdesk Support Desk"
                />
                Freshdesk Support Desk
              </h4>
              <ul className="product_features">
                <li>お客様からのお問い合わせを一元管理</li>
                <li>繰り返し行われるタスクを自動化</li>
                <li>お客様の自己解決のために、AIがFAQ記事を提案</li>
              </ul>
            </div>
            <div className="product">
              <h4 className="product_title">
                <img
                  src="/images/tp/messaging-brand-mark-1.svg"
                  width="37px"
                  height="38px"
                  alt="Freshdesk Messaging"
                />
                Freshdesk Messaging
              </h4>
              <ul className="product_features">
                <li>
                  積極的に顧客と関わるカスタマーサービスの実現ボットによる見込み客獲得
                </li>
                <li>
                  お客様ご自身で自己解決可能なセルフサービスとチャットを導入可能
                </li>
              </ul>
            </div>
            <div className="product">
              <h4 className="product_title">
                <img
                  src="/images/tp/contact-center-brand-mark.svg"
                  width="37px"
                  height="38px"
                  alt="Freshdesk Contact Center"
                />
                Freshdesk Contact Center
              </h4>
              <ul className="product_features">
                <li>グローバル事業でも利用可能なIP電話システム</li>
                <li>通話のモニタリングや通話指標の分析も可能</li>
                <li>IVRや自動通話録音と音声メッセージが利用可能</li>
                <li>国内のローカル番号による通話が可能</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`flex-1 ${productCardStyle} justify-between flex flex-col gap-y-[30px] lg:w-[400px] md:gap-y-2 [&_.product-card]:shadow-[0px_2px_6px_#00000040] [&_.product-card]:rounded-[10px] [&_.product-card]:p-[30px]`}
        >
          <div className="product-card">
            <h4 className="product_title">
              <img
                src="/images/tp/fsales-1.svg"
                width="37px"
                height="38px"
                alt="Freshsales"
              />
              Freshsales
            </h4>
            <ul className="product_features">
              <li>事業規模に合わせて自由自在にご利用可能なCRM</li>
              <li>AIが営業活動をサポート</li>
              <li>お客様の行動傾向が一目でわかるレポート機能</li>
            </ul>
          </div>
          <div className="product-card">
            <h4 className="product_title">
              <img
                src="/images/tp/fservicce.svg"
                width="37px"
                height="38px"
                alt="Freshservice"
              />
              Freshservice
            </h4>
            <ul className="product_features">
              <li>ITIL準拠のITサービス管理ツール</li>
              <li>簡単な設定ですぐに利用可能</li>
              <li>IT資産の徹底的な見える化</li>
            </ul>
          </div>
          <div className="product-card">
            <h4 className="product_title">freddy AI</h4>
            <ul className="product_features">
              <li>ディープデータ予測に基づくインサイト、助言、補正処理</li>
              <li>ワークフローの簡易化とタスクの自動化</li>
              <li>ユーザーのセルフサービス型CX（顧客体験）の向上させます</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="[&_h3]:text-[28px] [&_h3]:leading-[38px] [&_h3]:text-black [&_h3]:mb-5 [&_p]:text-sm [&_p]:leading-[25px] [&_p]:text-black">
        <h3 className="font-bold">キャンペーン概要</h3>
        <p className=" mb-[50px]">
          世界中で50,000社以上に利用されているビジネスソフトウェア Freshworks。
          <br />
          本キャンペーンは、Freshwroksの製品、カスタマーサービス、ITサービスマネジメント、HRマネジメント、セールス
          /
          マーケティングオートメーションのツール群を無料で利用できるキャンペーンです。
          <br />
          各製品につき、1,250USD（約15万円以上相当）分を利用可能な"クレジット特典"をご提供します。
        </p>
        <h3 className="font-bold">対象製品・特典</h3>
        <ol className="text-black text-sm leading-[25px] list-decimal pl-4 mb-[30px]">
          <li>Freshdesk Support Desk （1,250USDクレジット）</li>
          <li>Freshdesk Messaging（1,250USDクレジット）</li>
          <li>Freshservice（1,250USDクレジット）</li>
          <li>Freshsales Suite（1,250USDクレジット）</li>
          <li>Freshdesk Contact Center（1,250USDクレジット）</li>
          <li>Freshdesk Omnichannel（2,000USDクレジット）</li>
        </ol>
        <p>* いずれもProプランまたはEnterpriseプラン以上。</p>
        <p>* 5ユーザー以上のご契約が必要です。</p>
        <p>*クレジット特典を使える期間は1年以内です。</p>
        <h3 className="mt-[50px] font-bold">キャンペーン適用条件</h3>
        <ul className="list-disc pl-4 text-black text-sm leading-[25px]">
          <li>
            <a className="underline text-[#5D35D7]  " href="#registration">
              本キャンペーンへ申し込み
            </a>
            いただき、Freshworks製品をはじめてご利用される日本を拠点とする企業が対象となります。
          </li>
          <li>
            Freshworksの販売代理店 株式会社Digital
            Stacksと、1製品につき5ユーザー以上のご利用で契約をしていだくお客様が対象となります。
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CampaignOverview;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const WebinarRegisteration = () => {
  return (
    <div className="max-w-[863px] mx-auto px-4 xl:px-0 pb-20">
      <h2 className=" text-4xl leading-[50px] text-[#0B1320] text-center mb-[30px] font-bold">
        ウェビナー開催中
      </h2>
      <div className="flex gap-x-12 xl:gap-x-[108px] items-center flex-col-reverse sm:flex-row gap-y-8">
        <div className="flex-1 text-black text-sm leading-8 flex flex-col items-center sm:items-start">
          <p className="mb-5">
            Freshworksの概要、製品の概要デモ、キャンペーンの詳細をご案内する視聴型のウェビナーを開催しています。
          </p>
          <p className="mb-[30px]">
            約30分（出入り自由）の内容です。
            ざっくりご理解いただける内容ですので、お気軽にご参加ください。
          </p>
          <a
            className="py-[9px] block hover:text-black transition-all duration-500 rounded-[5px] text-white px-[17px] max-w-[330px] bg-[#5D35D7] hover:bg-transparent hover:border-[#5D35D7] border text-[15px] leading-4 text-center"
            href="https://digital-stacks-c.webinargeek.com/freshworks-startup"
            target="_blank"
            rel="noreferrer"
          >
            Freshworks スタートアップキャンペーン ウェビナー開催中
          </a>
        </div>
        <div className="flex-1 flex justify-center sm:justify-start">
          <StaticImage
            src="../../../../static/images/tp/webniar-img.png"
            quality={100}
            alt="Webinar Registeration"
          />
        </div>
      </div>
    </div>
  );
};

export default WebinarRegisteration;

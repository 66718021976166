import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

const FAQTab = ({ faq }) => {
  const [isAccordionOpen, toggleAccordion] = useState(faq.id === 1);
  return (
    <div className={`border-b border-b-[#A3B0BF] px-4  py-[14px]`}>
      <div
        className="flex justify-between items-center hover:cursor-pointer gap-x-2"
        onClick={() => toggleAccordion(!isAccordionOpen)}
      >
        <h3
          className={`${
            isAccordionOpen ? "text-[#3278B2] " : ""
          } text-base leading-6 font-bold`}
        >
          {faq.question}
        </h3>
        <button className="">
          {isAccordionOpen ? <BiMinus size={24} /> : <BiPlus size={24} />}
        </button>
      </div>
      <p
        className={`${
          isAccordionOpen ? "mt-5 h-full" : "h-0"
        } text-black text-base leading-6 overflow-hidden tracking-[0.96px] transition-all duration-200 ease-in-out`}
      >
        {faq.answer}
      </p>
    </div>
  );
};

export const FAQ = () => {
  return (
    <div className="px-4 xl:px-0 max-w-[863px] mx-auto">
      <h2 className=" text-4xl leading-[50px] text-[#0B1320] text-center mb-[42px] font-bold">
        FAQ
      </h2>
      <div className="border-t border-[#A3B0BF] mb-20">
        {faqs.map((faq, index) => (
          <FAQTab key={index} faq={faq} />
        ))}
      </div>
      <h2 className=" text-4xl leading-[50px] text-[#0B1320] text-center mb-[20px] relative font-bold">
        <span id="registration" className="absolute top-[-100px] left-0"></span>
        キャンペーンお申し込みフォーム
      </h2>
      <p className="text-sm leading-8">
        ご利用のインターネット環境によりフォームが表示されない場合がございます。入力フォームが表示されない場合は、こちらのメールアドレスまでお問い合わせください。
        <a
          href="mailto:info@digitalstacks.net"
          className="text-[#3478b2] underline"
        >
          info@digitalstacks.net
        </a>
        <br />
        <p className="text-[#ff0000]">*は必須項目</p>
        <iframe
          className="w-full min-h-[1100px] xl:min-h-[950px]"
          src="https://forms.zohopublic.com/digitalstacks/form/FreshworksCampaignRegistrationForm/formperma/H7ieWuGxMADRxu4qYFOG95uivSuj5EGsbxYGc5_uFXA?zf_rszfm=1"
        />
      </p>
    </div>
  );
};

const faqs = [
  {
    id: 1,
    question: "どのようにクレジット特典を受けられるのですか？",
    answer:
      "「キャンペーンにお申込み」いただいたのち、弊社より見積書を送付します。発注書を送付いただいたのち、アカウントが提供されます。",
  },
  {
    id: 2,
    question: "各製品のクレジット特典を他の製品に移管したり、合算できますか？",
    answer:
      "残念ながらできません。各製品ごとのクレジット特典の上限（1,250USドル分 ※）までご利用いただけます。\n※Freshdesk Omnichannelは2,000USドル分",
  },
  {
    id: 3,
    question: "クレジット特典の有効期限はありますか？",
    answer:
      "最初の製品のアカウント開始から1年間です。クレジットが余っていても、その期間後は使用できません。",
  },
  {
    id: 4,
    question: "クレジットはどの製品プランで利用できますか?",
    answer: "クレジットは、Pro および Enterprise プランで利用できます。",
  },
  {
    id: 5,
    question: "クレジットは、現在のアカウントに付与できますか？",
    answer:
      "本キャンペーンは、Freshworks製品をはじめてご利用される企業が対象となります。\nすでにFreshworks製品をご利用いただいている方、過去にトライアルを実施されたお客様は本キャンペーンは適用されません。",
  },
];

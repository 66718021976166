import React from "react";
import Layout from "../../../components/common/Layout";
import Meta from "../../../components/common/Meta";
import AboutFreshworks from "../../../components/freshworks/startups/About";
import CampaignOverview from "../../../components/freshworks/startups/CampaignOverview";
import { FAQ } from "../../../components/freshworks/startups/FAQ";
import HeroSection from "../../../components/freshworks/startups/Hero";
import WebinarRegisteration from "../../../components/freshworks/startups/WebinarRegisteration";
import WebinarTab from "../../../components/freshworks/startups/WebinarTab";

const Startups = () => {
  const meta = {
    title: "Freshworks｜正規販売代理店（株）Digital Stacks",
    description:
      "Freshworksは、非常に使いやすいマーケティング、販売、サポート、および ITソリューション向けの最新の使いやすい顧客および従業員エクスペリエンス ソフトウェアを提供しています。 ",
    pathname: "/tp/freshworks/startups/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <WebinarTab
        title="キャンペーン概要ウェビナー開催中"
        link="https://digital-stacks-c.webinargeek.com/freshworks-startup"
      />
      <HeroSection />
      <AboutFreshworks />
      <CampaignOverview />
      <WebinarRegisteration />
      <FAQ />
    </Layout>
  );
};

export default Startups;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const AboutFreshworks = () => {
  return (
    <div className="py-20 px-4 xl:px-10">
      <h2 className="text-4xl leading-[50px] text-[#0B1320] text-center mb-[50px] font-bold">
        Freshworks For Startups
      </h2>
      <div className="flex gap-x-12 xl:gap-x-28 flex-col sm:flex-row gap-y-8">
        <div className="flex-1">
          <div className="hidden sm:block">
            <StaticImage
              src="../../../../static/images/tp/overview-img.png"
              quality={100}
              alt="Overview Banner"
            />
          </div>
          <div className="flex justify-center sm:hidden">
            <StaticImage
              src="../../../../static/images/tp/mobile-overview-img.png"
              quality={100}
              alt="Mobile Overview Banner"
            />
          </div>
        </div>

        <div className="flex-1 text-black text-sm leading-8 tracking-[0.14px]">
          <p>スタートアップの旅は大変です。</p>
          <p>
            かつてスタートアップだったFreshworksは、それをよく理解しています。
          </p>
          <p>
            そしてFreshworksは、チームが実際に使いたくなるような、驚くほど簡単なソフトウェアを作成しました。リーズナブルで速やかに導入できるだけでなく、エンドユーザーのことを考えた設計となっています。
          </p>
          <p>
            Freshworksは、スタートアップの成長に欠かせないツール群の利用を支援いたします。
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutFreshworks;

import React from "react";
import { twMerge } from "tailwind-merge";

const WebinarTab = ({ title, link }) => {
  return (
    <div className={twMerge(`relative w-full h-14 lg:h-0`)}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={twMerge(
          `flex justify-center gap-x-4 lg:justify-between p-4 w-[100%] top-0 left-0 lg:w-[400px] absolute lg:left-5 xl:left-10 lg:top-[13px] lg:rounded-b-[10px] z-[2] `
        )}
        style={{ backgroundColor: "#000" }}
      >
        <span className="text-white text-base sm:text-[20px] leading-6 font-bold">
          {title}
        </span>
        <img
          width="22px"
          height="22px"
          src="/images/tp/play_icon.svg"
          alt="Play Icon"
        />
      </a>
    </div>
  );
};

export default WebinarTab;

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const HeroSection = () => {
  return (
    <div
      className={`w-full px-4 xl:px-10 flex relative gap-x-16 h-[465px] py-10 lg:h-[400px] justify-between items-center font-main`}
    >
      <StaticImage
        src="../../../../static/images/tp/freshworks-campaign-Banner-bgn.png"
        layout="fullWidth"
        objectFit="cover"
        alt="Freshworks Banner"
        loading="eager"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
          left: 0,
        }}
        quality={100}
      />
      <div className="flex justify-center flex-col items-center lg:block lg:basis-[55%] pt-10">
        <div className="text-[30px] leading-[30px] text-[#7a7a7a] flex gap-x-2 mb-5">
          <img src="/images/tp/freshworks.svg" width="200px" height="40px" />
          <span>for Startup</span>
        </div>
        <h1 className="leading-12 md:leading-[50px] text-center lg:text-left text-4xl tracking-[0.14px] mb-5 font-bold text-[#0b1320]">
          スタートアップ応援キャンペーン
        </h1>
        <p className="text-base text-center lg:text-left leading-6 mb-5 text-[#0B1320]">
          スタートアップ企業に必要な顧客・コミュニケーション管理のツールを無料で利用可能。
          1,250USD（約15万円以上相当）分のクレジット特典をご提供します。
        </p>
        <Link
          className="bg-[#5D35D7] hover:text-[#5D35D7] hover:border-[#5D35D7] border border-transparent text-[15px] leading-[15px] py-3 inline-flex px-[18px] text-white rounded-full transition-all duration-500 hover:bg-transparent"
          to="/tp/freshworks/startups/#registration"
        >
          キャンペーンに申し込む
        </Link>
      </div>
      <div className="basis-[40%] pt-10 hidden lg:block">
        <StaticImage
          src="../../../../static/images/tp/banner-img.png"
          alt="Campaign Banner"
          objectFit="cover"
        />
      </div>
    </div>
  );
};

export default HeroSection;
